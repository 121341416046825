<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("schools.title") }}
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4" v-if="showMainDepartment()">
                  <b-form-group>
                    <template v-slot:label>
                      Район
                    </template>
                    <b-form-select
                        v-model="filter.regionId"
                        :options="regions"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }">{{
                  $t("common.filter")
                }}
              </b-button>
            </b-form>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">{{ $t("schools.list.nameRu") }}</th>
                <th class="text-left">{{ $t("schools.list.nameKg") }}</th>
                <th class="text-left">{{ $t("schools.list.region") }}</th>
                <th class="text-left">{{ $t("schools.list.address") }}</th>
                <th class="text-left">
                  {{ $t("schools.list.contactPhone") }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in schools" :key="item.id">
                <td>{{ item.nameRu }}</td>
                <td>{{ item.nameKg }}</td>
                <td>{{ item.region }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.contactPhone }}</td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
  td>button{
    margin-right: 10px;
  }
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import {mapGetters} from "vuex";

export default {
  name: "EducationDepartmentSchools",
  components: {
    KTPortlet
  },
  data() {
    return {
      schools: null,
      dialog: true,
      regions:[],
      form: {
        nameRu: "",
        nameKg: "",
        address: "",
        contactPhone: "",
        regionId: ""
      },
      filter:{
        regionId: 0
      },
      show: true,
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  mounted() {
    ApiService.querySecured("dictionaries/regions", {}).then(({data}) => {
      this.regions = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
    
    this.reloadData();
  },
  methods: {
    reloadData() {
      let $this = this;
      $this.loading = true;
      ApiService.postSecured("educationDepartment/schools", this.filter)
          .then(({ data }) => {
            $this.schools = data.list;
          })
          .catch(({ response }) => {
            console.log(response);
          }).finally(function (){
        $this.loading = false;
      });
    },
    showMainDepartment(){
      return this.currentUser.role == 'ROLE_MAIN_EDUCATION_DEPARTMENT';
    }
  }
};
</script>
